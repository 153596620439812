<template>
  <div class="frontpage-profile-popup-wrapper" @click="$emit('close')">
    <div
      v-show="!languageSelectorVisible"
      class="frontpage-profile-popup"
      :style="{ top: originDiv ? originDiv.y + 14 + 'px' : '100px' }"
    >
      <div class="popover-arrow" :style="{ right: originDiv ? originDiv['margin-right'] - 4 + 'px' : '50px' }"></div>
      <div v-if="!isLobby" class="frontpage-profile-row" @click.stop="$event => showProfile()">
        <div class="frontpage-show-profile">{{ getString("MY_PROFILE") }}</div>
        <img src="../../../assets/arrowdark@2x.png" class="right-arrow" />
      </div>
      <div class="frontpage-language" @click.stop="showLanguageSelector()">
        <div class="frontpage-language-title">{{ getString("FRONTPAGE_SELECT_LANGUAGE") }}</div>
        <div v-if="currentLanguage" class="frontpage-selected-language">{{ uppercase(currentLanguage) }}</div>
      </div>
    </div>
    <div v-if="languageSelectorVisible" class="language-selector animated fadeIn">
      <div class="language-selector-background">
        <div
          v-for="language in languages"
          :key="'language-' + language.languageCode"
          class="language-button"
          :class="[currentLanguage === language.languageCode ? 'selected' : 0]"
          @click="languageChanged(language.languageCode)"
        >
          {{ getLanguageName(language.languageCode) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/services/mixin";
import * as types from "@/store/mutation-types";
import languages from "@/utils/languages";
import { getCurrentLanguage } from "@/services/utils";

export default {
  mixins: [mixin],
  props: {
    originDiv: Object,
    showLanguageSelectorOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["showProfile", "close"],
  data() {
    return {
      currentLanguage: "",
      languageSelectorVisible: false,
      availableLanguages: languages
    };
  },
  computed: {
    isLobby() {
      return this.$store.state.isLobby;
    },
    languages() {
      return this.$store.state.establishment.languages;
    }
  },
  created() {
    this.currentLanguage = getCurrentLanguage(this.languages);

    if (!this.$store.state.interfaceLanguage) {
      this.$store.commit(types.INTERFACE_LANGUAGE, this.currentLanguage);
    }

    if (this.showLanguageSelectorOnly) {
      this.languageSelectorVisible = true;
    }
  },
  methods: {
    showProfile() {
      this.$emit("showProfile");
      this.$emit("close");
    },
    languageChanged(languageCode) {
      this.$store.commit(types.INTERFACE_LANGUAGE, languageCode);
      this.$store.commit(types.SET_USER_LOCALE, languageCode);
      this.currentLanguage = languageCode;
      this.$emit("close");
    },
    uppercase(string) {
      return string.toUpperCase();
    },
    showLanguageSelector() {
      if (this.languages?.length > 1) {
        this.languageSelectorVisible = true;
      }
    },
    getLanguageName(code) {
      const found = this.availableLanguages.find(lang => lang.iso === code);
      return found?.name ?? code;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.frontpage-profile-popup-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, 0.5);
}
.frontpage-profile-popup {
  background-color: white;
  padding: 16px;
  width: 150px;
  position: absolute;
  right: 16px;
  top: 0;
  border-radius: 4px;
}
.popover {
  position: relative;
  width: 200px;
  height: 100px;
  background-color: rgb(233, 212, 222);
  border-radius: 4px;
  top: 50px;
  left: 50px;
  box-shadow: 3px -2px 4px #aaa;
}

.popover-arrow {
  position: absolute;
  top: -10px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
  width: 0;
  height: 0;

  -webkit-filter: drop-shadow(0 -1px 0px #aaa);
}
.frontpage-profile-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid @color-gray-2;
  padding-bottom: 16px;
}
.frontpage-show-profile {
  .h4-black;
}
.right-arrow {
  margin-right: 4px;
  width: 8px;
  height: 14x;
}

.frontpage-language {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.frontpage-language-title {
  .h4-black;
}
.frontpage-selected-language {
  .caption-black;
  background-color: rgba(120, 102, 254, 0.1);
  border-radius: 16px;
  padding: 4px 12px;
}
.input-group-language {
  .h3-black;
}
.language-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
}
.language-selector-background {
  width: 300px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 12px 0 0 0;
}
.language-button {
  width: 250px;
  padding: 12px 0;
  border-radius: 30px;
  text-align: center;
  border: 1px solid #ebedf0;
  margin-bottom: 16px;
  .body-grey;
  .medium;
  &.selected {
    .body-white;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
  }
}
</style>
